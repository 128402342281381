import styled from 'styled-components';

export const Viewport = styled.header`
  height: 180px;
  width: 100vw;
  background: #2B2B2B;
  padding: 56px 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const Menu = styled.div`
  padding: 20px 25px
`;

export const Logo = styled.img``;

export const Comment = styled.div`
  padding: 20px 25px
`;
