import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Base from '@views/Base';
import Chords from '@views/Chords';
import Export from '@views/Export';
import Home from '@views/Home';
import PostPrint from '@views/PostPrint';
import PrePrint from '@views/PrePrint';
import Print from '@views/Print';
import Register from '@views/Register';
import Search from '@views/Search';
import Signin from '@views/Signin';
import Ticket from '@views/Ticket';
import ViewTicket from '@views/ViewTicket';

import Route from './Route';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/entrar" component={Signin} exact />
      <Route path="/" component={Home} exact isPrivate />
      <Route path="/registro" component={Register} exact isPrivate />
      <Route path="/busca" component={Search} exact isPrivate />
      <Route path="/export" component={Export} exact isPrivate />
      <Route path="/base" component={Base} exact isPrivate />
      <Route path="/acordes" component={Chords} exact isPrivate />
      <Route path="/ticket" component={Ticket} exact isPrivate />
      <Route path="/preview" component={ViewTicket} exact isPrivate />
      <Route path="/configurar-impressao" component={PrePrint} exact isPrivate />
      <Route path="/concluido" component={PostPrint} exact isPrivate />
      <Route path="/impressao" component={Print} />
    </Switch>

    <ToastContainer
      limit={1}
      position="top-center"
      autoClose={5000}
      hideProgressBar={!!true}
    />
  </BrowserRouter>
);

export default Router;
