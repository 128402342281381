import styled from 'styled-components';

import { Gray500, Primary, Secondary } from '@assets/styles/colors';
import { LabelLarge, ParagraphSmall } from '@assets/styles/typography';

export const StyledOption = styled.button`
  width: 282px;
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${props => props.selected ? `${Secondary}` : '#343434'};
  border-radius: 16px;
  padding: 0 0;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-bottom: 35px;
  cursor: default;
  text-align: left;
  box-shadow: 0px 14px 24px 0px rgba(0,0,0,0.36);
`;

export const OptionName = styled.span`
  ${LabelLarge};
  letter-spacing: 0;
  font-weight: 600;
  display: block;
  color: ${props => props.selected ? `${Primary}` : `${Secondary}`};
  margin-bottom: 6px;
`;

export const OptionDescription = styled.span`
  ${ParagraphSmall};
  letter-spacing: 0;
  color: ${props => props.selected ? `${Primary}` : `${Gray500}`};
`;

export const Image = styled.img`
  width: 91px;
  margin-right: 11px;
`;

export const Content = styled.div``;
