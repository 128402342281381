import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TextArea from './TextArea';

const ControlledInput = ({ label, placeholder, name, defaultValue, control, rules, error, style }) => {
  return (
    <Controller
      as={TextArea({ label, placeholder, error, value: defaultValue, style })}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

ControlledInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  control: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.func]).isRequired,
  rules: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  error: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
};

ControlledInput.defaultProps = {
  label: undefined,
  placeholder: undefined,
  rules: undefined,
  error: undefined,
  style: undefined,
};

export default ControlledInput;
