export const gotoFullscreen = () => {
  const element = document.documentElement;

  try {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
    }
  } catch (e) {
    console.error(e); // Do not break application
  }
};

export default gotoFullscreen;
