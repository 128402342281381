import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import arrowImage from '@assets/images/arrow.svg';
import personImage from '@assets/images/person.svg';
import configImage from '@assets/images/config.svg';
import ticketImage from '@assets/images/ticket.svg';
import exitImage from '@assets/images/exit.svg';

import CreatorContext from '@context/creatorContext';
import Button from '@components/molecules/Button';

import {
  SET_CREATOR_CURRENT,
  RESET_STATE,
} from '@context/consts';

import {
  CloseOverlay,
  Viewport,
  ViewportConfirmation,
  Close,
  Actions,
  Item,
  Label,
  Exit,
} from '@components/molecules/NavBar/style';



const NavBar = ({ show, showChange }) => {
  const { dispatch, state } = useContext(CreatorContext);
  const history = useHistory();
  const list = ['/registro','/base','/acordes','/ticket','/impressao'];
  const index = list.indexOf(history.location.pathname);

  const [confirmation, setConfirmation] = useState(false  );

  // const [Confirmation, showConfirmation] = useConfirmation({
  //   title: 'Encerrar Atendimento',
  //   message: 'Ao encerrar o atendimento você não finaliza a criação da fragência',
  //   buttons: {
  //     cancel: 'Cancelar',
  //     confirm: 'Encerrar assim mesmo',
  //   }
  // });

  const [register, changeRegister] = useState(false)
  const [fragrance1, changeFragrance1] = useState(false)
  const [fragrance2, changeFragrance2] = useState(false)

  const closeNav = () => {
    showChange();
  }

  const showConfirmation = () => {
    setConfirmation(true);
  }

  const onConfirmCancel = () => {
    setConfirmation(false);
  }

  const onConfirm = () => {
    setConfirmation(false);

    dispatch({
      type: RESET_STATE
    });
    closeNav();
    history.push('/');
  }

  const exit = () => {
    showConfirmation({
      onConfirm
    });
  }

  const goFragrance = id => {
    dispatch({
      type: SET_CREATOR_CURRENT,
      payload: id
    });
    closeNav();
    history.push('/acordes');
  };

  useEffect(() => {
    if (state.name && state.email) {
      changeRegister(true);

      if (state.currentCreating >= 0) changeFragrance1(true);
      if (state.currentCreating >= 1) changeFragrance2(true);
    }
  }, [state])

  return (
    <>
      <CloseOverlay show={show} onClick={closeNav} />
      <Viewport show={show}>
        <Close onClick={closeNav}>
          <img src={arrowImage} alt="Fechar Menu" />
        </Close>
        <Actions>
          <Item active={register} onClick={() => { history.push('/registro'); }}>
            <img src={personImage} alt="Cadastro do cliente" />
            <Label>Cadastro do cliente</Label>
          </Item>
          <Item active={fragrance1} onClick={() => goFragrance(0)}>
            <img src={configImage} alt="Criar Fragrância 1" />
            <Label>Criar Fragrância 1</Label>
          </Item>
          <Item active={fragrance2} onClick={() => goFragrance(1)}>
            <img src={configImage} alt="Criar Fragrância 2" />
            <Label>Criar Fragrância 2</Label>
          </Item>
          <Item active={index >= 3}>
            <img src={ticketImage} alt="Imprimir etiqueta" />
            <Label>Imprimir etiqueta</Label>
          </Item>
        </Actions>
        <Exit onClick={exit}>
          <img src={exitImage} alt="Fechar Menu" />
          <Label>Encerrar atendimento</Label>
        </Exit>
      </Viewport>

      <ViewportConfirmation show={confirmation}>
        <div className="confirmation-wrapper">
          <h2>Encerrar Atendimento</h2>
          <p>Ao encerrar o atendimento você não finaliza a criação da fragância</p>

          <Button onClick={onConfirmCancel} reverseArrow border>Cancelar</Button>
          <Button onClick={onConfirm} type="dark">Encerrar Assim Mesmo</Button>
        </div>
      </ViewportConfirmation>
    </>
  );
};

NavBar.propTypes = {
  show: PropTypes.bool,
  showChange: PropTypes.func.isRequired
};

NavBar.defaultProps = {
  show: false
};

export default NavBar;
