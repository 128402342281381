import styled, { createGlobalStyle } from 'styled-components';
import debugBg from '@assets/images/debug.png';

export const PrintPage = createGlobalStyle`
  @page {
    margin: 1.1mm;
    size: A4
  }

  * {
    -webkit-text-size-adjust: none;
  }
  
  .print {
    display: none;
  }
  
  .noprint {
    background: #2B2B2B;
  } 
  
  body {
    margin: 0;
    // width: 129mm;
    // height: 205mm;
    background: #eee;
    overflow: hidden;
    // border: solid 1px #ddd;
  }
  
  .flex-wrapper {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  @media print { 
     .noprint { 
        display: none; 
     }
     .flex-wrapper {
        display: flex!important;
     }
  }

  .ticket {
    width: 119mm;
    height: 35mm;
    background: #ddd;
    margin-bottom: 6.4mm;
    // border: dashed 1px #888;
    position: relative;
    box-sizing: border-box;
    background: none;
  }
  
  .ticket:before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    opacity: .1; 
    z-index: -1;
    // background-image: url('${debugBg}');
    background-size: cover;
  }
`;

export const Signature = styled.span`
  font-size: 8pt;
  line-height: 1;
`;

export const By = styled.span`
  font-size: 6.5pt;
  line-height: 1;
  margin-right: 0.5mm;
`;

export const FraganceName = styled.span`
  font-size: 9pt;
  line-height: 1;
  display: block;
`;

export const ProductCode = styled.span`
  font-size: 4pt;
  position: absolute;
  top: 14.8mm;
  left: 102mm;
`;

export const Lot  = styled.span`
  font-size: 4pt;
  display: block;
  white-space: nowrap;
  transform: scale(0.70);
  transform-origin: 0% 50%;
`;

export const Title = styled.div`
  position: absolute;
  top: 12.505mm;
  left: 0;
  right: 0;
  line-height: 6pt;
  text-align: center;
  font-family: 'Domaine';
  padding-right: 1mm;
`;

export const Validity = styled.div`
  position: absolute;
  top: 21.2mm;
  left: 104mm;
  width: 8.618mm;
  line-height: 1;
  text-align: center;
`;
