import styled from 'styled-components';

import { HeadingSmall, LabelMedium, Paragraph, ParagraphHeight } from '@assets/styles/typography';
import { Primary, Error as ErrorColor, Secondary, Gray500, White } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  padding: 130px 100px 0;
  background: ${Primary};
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 150px 0 70px 0;
  align-self: center;
`;

export const Label = styled.span`
  ${ParagraphHeight};
  color: ${Gray500};
  margin-top: 16px;
`;

export const Title = styled.h1`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
`;

export const Form = styled.form`
`;

export const LabelForm = styled.span`
  ${Paragraph};
  color: ${White};
  line-height: 32px;
`;

export const RadioGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 45px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 90px;
`;

export const Error = styled.span`
  ${LabelMedium};
  visibility: ${props => props.error ? 'visible' : 'hidden'};
  opacity: ${props => props.error ? '1' : '0'};
  position: absolute;
  bottom: ${props => props.error ? '-22px' : '-10px'};
  color: ${ErrorColor};
  display: block;
  transition: .3s cubic-bezier(.215,.61,.355,1);
`;
