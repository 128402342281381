import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Paragraph, ParagraphSmall, HeadingLarge, HeadingMedium } from '@assets/styles/typography';
import { Primary, Secondary, Gray500 } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0;

  background: ${Primary};
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 200px 0 100px 0;
  max-width: 320px;
  align-self: center;
`;

export const Label = styled.span`
  ${Paragraph};
  color: ${Gray500};
  margin-bottom: 16px;
  text-align: center;
  display: block;
`;

export const Title = styled.h1`
  ${HeadingLarge};
  color: ${Secondary};
  text-align: center;
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`;

export const OptionItem = styled(Link)`
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #343434;
  border-radius: 16px;
  color: ${Secondary};
  border-left: 16px solid;
  padding: 48px;
  outline: none;
  margin: 40px 0;
`;

export const OptionLabel = styled.span`
  ${HeadingMedium};
  font-weight: 600;
  color: ${Secondary};
`;

export const OptionDescription = styled.span`
  ${ParagraphSmall};
  color: ${Gray500};
`;
