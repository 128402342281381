import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getErrorMessageByRequest } from '@modules/errors';
import FormService from '@api/services/form';

import CreatorContext from '@context/creatorContext';

import Page from '@components/templates/Page';
import Loader from '@components/atoms/Loader';
import ImageCard from '@components/molecules/ImageCard';
import ColorCard from '@components/molecules/ColorCard';
import Button from '@components/molecules/Button';

import logoImage from '@assets/images/logo.svg';
import {ReactComponent as ArrowImage} from '@assets/images/arrow.svg';
import searchImage from '@assets/images/search.svg';

import { SET_CREATOR, SET_CREATOR_FRAGRANCE } from '@context/consts';

import BottomContainer from '@components/molecules/BottomContainer';
import {
  PageWrapper,
  Logo,
  TitleContainer,
  Title,
  Form,
  Input,
  SearchIcon,
  Arrow,
  ResultWrapper,
  Card,
  SubTitle,
  DetailShow,
  FraganceSelected,
  ColTitle,
  InfosContainer,
  InfoItem,
  InfoLabel,
  InfoDetail,
  Perfumer,
} from './style';

const Home = () => {
  const [top, setTop] = useState(0);
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState(false);
  const history = useHistory();

  const { dispatch } = useContext(CreatorContext);

  const searchData = async text => {
    try {
      setLoading(true);
      const { data } = await FormService.search({
        query: text
      });
      setLoading(false);
      return data;

    } catch(err) {
      const message = getErrorMessageByRequest(err);
      return toast.error(message);
    }
  };

  const find = async () => {
    if (search.length > 0) {
      const data = await searchData(search);
      setSearchResult(data);
    }
  }

  const showFragrance = id => {
    const data = searchResult.filter(item => item.id === id);
    setShowData(data && data.length > 0 && data[0]);
    setShow(true);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      find();
    }, 500);
    return () => clearTimeout(timer);
  }, [search])

  const formaLot = lot => {
    const lotS = `${lot}`;
    const pad = "0000";
    const str = pad.substring(0, pad.length - lotS.length) + lotS;
    return str;
  }

  const createNew = () => {
    const data = showData.variants;
    data[0].locked = true;

    dispatch({
      type: SET_CREATOR,
      payload: showData,
    });

    dispatch({
      type: SET_CREATOR_FRAGRANCE,
      payload: [{
        variants: data
      }]
    });

    history.push('/registro');
  }

  useEffect(() => {
    const el = document.querySelector('.formSearchFrag');
    const t = el.getBoundingClientRect();
    setTop(t.top + 120);
  }, []);

  return (
    <Page title="Home" description="Welcome to Nodo Csr!">
      <PageWrapper>
        <Arrow onClick={() => { history.push('/') }}>
          <ArrowImage />
        </Arrow>
        <Logo src={logoImage} alt="Privée" />

        {!show && (
          <div>
            <TitleContainer>
              <Title>Buscar Fragrância</Title>
            </TitleContainer>

            <Form className="formSearchFrag">
              <Input
                type="text"
                placeholder="Nome do cliente ou código do produto"
                name="search"
                onChange={e => setSearch(e.target.value)}
                autoComplete="off"
              />
              <SearchIcon src={searchImage} alt="Buscar" />
            </Form>

            <ResultWrapper top={top}>
              {loading && <Loader className="loader" size="large" colorSchema="secondary" />}
              {!loading && searchResult.length > 0 && searchResult.map(item => (
                <Card key={item.id} onClick={() => { showFragrance(item.id) }}>
                  <span>{item.name}</span>
                  <span>{item.fraganceName}</span>
                </Card>
              ))}
            </ResultWrapper>
          </div>
        )}

        {show && showData && (
          <DetailShow>
            <Title>{showData.fraganceName}</Title>
            <SubTitle>
              {`Fragrância criada para `}
              <span>{`${showData.signature}.`}</span>
            </SubTitle>

            <FraganceSelected>
              <div>
                <ColTitle>Base</ColTitle>
                <ImageCard
                  key={showData.variants[0].code}
                  name={showData.variants[0].name}
                  imgSrc={`/static/img/bases/${showData.variants[0].code}.png`}
                  description={showData.variants[0].reference}
                  onClick={() => {}}
                />
              </div>
              <div>
                <ColTitle>Acordes</ColTitle>
                {showData.variants[0].chords.map(chord => (
                  <ColorCard
                    key={chord.code}
                    name={`${chord.name} ${chord.quantity}%`}
                    size="small"
                    color={chord.color}
                    onClick={() => {}}
                  />
                ))}
              </div>
            </FraganceSelected>
            <InfosContainer>
              <InfoItem>
                <InfoLabel>Código do produto</InfoLabel>
                <InfoDetail>{showData.productCode}</InfoDetail>
              </InfoItem>

              <InfoItem>
                <InfoLabel>Lote do produto</InfoLabel>
                <InfoDetail>{formaLot(showData.lot)}</InfoDetail>
              </InfoItem>
            </InfosContainer>
            <Perfumer>
              <small>Perfumista responsável</small>
              <span>{showData.perfumer}</span>
            </Perfumer>

            <BottomContainer>
              <BottomContainer.Item>
                <Button onClick={createNew} reverseArrow>
                  CRIAR FRAGRÂNCIA COM ESSES DADOS
                </Button>
              </BottomContainer.Item>
            </BottomContainer>
          </DetailShow>
        )}
      </PageWrapper>
    </Page>
  )
}

export default Home;
