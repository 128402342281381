import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledOption,
  OptionName,
  OptionDescription,
  BorderColor,
  Content,
} from './style';

const ColorCard = ({ name, description, selected, available, color, size, ...rest }) => {
  return (
    <StyledOption selected={selected} disabled={!available} color={color} {...rest}>
      <BorderColor color={color} />
      <Content>
        <OptionName size={size} selected={selected}>{name}</OptionName>
        <OptionDescription selected={selected}>{description}</OptionDescription>
      </Content>
    </StyledOption>
  );
};

ColorCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  available: PropTypes.bool,
  size: PropTypes.oneOf(['small','normal']),
};

ColorCard.defaultProps = {
  selected: false,
  available: true,
  size: 'normal',
};

export default ColorCard;
