import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { getErrorMessageByRequest } from '@modules/errors';
import FormService from '@api/services/form';

import * as moment from 'moment';

import Page from '@components/templates/Page';
import Button from '@components/molecules/Button';
import Input from '@components/molecules/Input';

import logoImage from '@assets/images/logo.svg';
import { ReactComponent as ArrowImage } from '@assets/images/arrow.svg';

import {
  PageWrapper,
  Logo,
  TitleContainer,
  Title,
  Form,
  ButtonContainer,
  Arrow,
} from './style';

const Home = () => {
  const [startDate, setStartDate] = useState(moment().startOf('month').format('DD/MM/YYYY'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('DD/MM/YYYY'));
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { handleSubmit, control, errors } = useForm();
  const [submited, setSubmited] = useState(false);

  const searchData = async (start, end) => {
    try {
      setLoading(true);
      const { data } = await FormService.export({
        startDate: start,
        endDate: end,
      });
      setLoading(false);
      return data;

    } catch (err) {
      const message = getErrorMessageByRequest(err);
      return toast.error(message);
    }
  };


  function onInvalid() {
    setSubmited(true);
  };

  useEffect(() => {
    // Trigger callback when there's errors
    if (Object.keys(errors).length !== 0) {
      onInvalid();
    }
  }, [JSON.stringify(errors)]);

  function exportEnabled() {
    const formatedStartDate = startDate.replace(/_/g, '');
    const formatedEndDate = endDate.replace(/_/g, '');
    const start = moment(formatedStartDate, 'DD/MM/YYYY');
    const end = moment(formatedEndDate, 'DD/MM/YYYY');
    return start.isValid() && end.isValid() && start.isSameOrBefore(end);
  }

  async function exportXLSX() {
    const start = moment(startDate, 'DD/MM/YYYY');
    const end = moment(endDate, 'DD/MM/YYYY');
    const csv = await searchData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csv], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }));
    a.download = 'export.xlsx';
    a.click();
  }

  async function onSubmit() {
    const enabled = exportEnabled();
    if (enabled) {
      exportXLSX()
    }
  };

  useEffect(() => {
    exportEnabled();
  }, [startDate, endDate]);

  return (
    <Page title="Home" description="Welcome to Nodo Csr!">
      <PageWrapper>
        <Arrow onClick={() => { history.push('/') }}>
          <ArrowImage />
        </Arrow>
        <Logo src={logoImage} alt="Privée" />

        <TitleContainer>
          <Title>Buscar Relatório</Title>
        </TitleContainer>

        <Form>
          <Input
            mask="99/99/9999"
            type="text"
            placeholder="Data inicial"
            name="startDate"
            onChange={e => setStartDate(e.target.value)}
            autoComplete="off"
            control={control}
            error={errors.name}
            defaultValue={startDate || ''}
          />
          <Input
            mask="99/99/9999"
            type="text"
            placeholder="Data final"
            name="endDate"
            onChange={e => setEndDate(e.target.value)}
            autoComplete="off"
            control={control}
            error={errors.name}
            defaultValue={endDate || ''}
          />
          <ButtonContainer>
            <Button
              onClick={onSubmit}
              disabled={!exportEnabled()}
            >
              Download
            </Button>
          </ButtonContainer>
        </Form>
      </PageWrapper>
    </Page>
  )
}

export default Home;