import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR_FRAGRANCE } from '@context/consts';

import Loader from '@components/atoms/Loader';
import ColorCard from '@components/molecules/ColorCard';
import Button from '@components/molecules/Button';

import loadForm from '@utils/form';

import Transition from '@components/molecules/Transition';
import BottomContainer from '@components/molecules/BottomContainer';
import { Label, ChordsContainer, LoaderWrapper } from './style';

const SelectChords = ({ setStep, activeVariant }) => {
  const [loading, setLoading] = useState(false);
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);
  const [chords, setChords] = useState([]);

  const { fragrances, currentCreating } = creatorState;
  const fragrance = fragrances[currentCreating];
  const [selectedChords, setSelectedChords] = useState(fragrance.variants[activeVariant-1].chords || []);

  function defaultValue(chordsList, position = null) {
    const num = chordsList.length;

    if (num === 3) return 1;
    if (num === 2 && position !== null) return position === 0 ? 2 : 1;
    return 3;
  }

  function saveState(newChords) {
    const normalize = newChords.map((item, index) => {
      /* eslint-disable */
      item.quantity = defaultValue(newChords, index);
      /* eslint-enable */
      return item;
    })
    const variant = fragrance.variants[activeVariant-1];
    variant.chords = normalize;
    creatorDispatch({ type: SET_CREATOR_FRAGRANCE, payload: fragrances });
  }

  const loadChords = useCallback(async () => {
    setLoading(true);

    const data = await loadForm();
    setChords(data.chords);

    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await loadChords();

      if (creatorState.fragrances[creatorState.currentCreating]?.variants[activeVariant-1].locked) {
        setSelectedChords(creatorState.fragrances[creatorState.currentCreating]?.variants[activeVariant-1].chords);
        setStep(2);
      }
    })()
  }, [activeVariant]);

  function handleSelectedChord(chord) {
    const data = {
      code: chord.code,
      name: chord.name,
      color: chord.color,
      quantity: 5,
    };

    const alreadyExists = selectedChords.some(chordItem => chordItem.code === chord.code);

    if (alreadyExists) {
      const filteredChords = selectedChords.filter(chordItem => chordItem.code !== chord.code);
      setSelectedChords(filteredChords);
    }

    if (selectedChords.length < 3 && !alreadyExists) {
      setSelectedChords([...selectedChords, data]);
    }

    saveState([...selectedChords, data]);
  }

  const available = useMemo(() => {
    if (selectedChords.length >= 3) {
      return false;
    }

    return true;
  }, [selectedChords]);

  function checkIsSelected(code) {
    const findIndex = selectedChords.findIndex(item => item.code === code);

    if (findIndex >= 0) {
      return true;
    }

    return false;
  }

  function handleNext() {
    saveState(selectedChords);
    setStep(2);
  }

  return (
    loading ? (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    ) : (
      <Transition type="fadein" trigger>
        <>
          <Label>Escolha até 3 acordes:</Label>

          <ChordsContainer>
            {chords.map(chord => {
              return (
                <ColorCard
                  key={chord.code}
                  name={chord.name}
                  color={chord.color}
                  onClick={() => handleSelectedChord(chord)}
                  selected={checkIsSelected(chord.code)}
                  available={available || checkIsSelected(chord.code)}
                />
              )
            })}
          </ChordsContainer>

          <BottomContainer>
            <Button type="primary" onClick={handleNext} disabled={!(selectedChords.length > 0)}>
              DEFINIR CONCENTRAÇÃO
            </Button>
          </BottomContainer>
        </>
      </Transition>
    )
  )
}

SelectChords.propTypes = {
  setStep: PropTypes.func,
  activeVariant: PropTypes.number,
};

SelectChords.defaultProps = {
  setStep: undefined,
  activeVariant: 1,
};

export default SelectChords;
