import React, { useContext, useEffect, useState } from 'react';
import Check from '@components/atoms/Check';
import { useHistory } from 'react-router-dom';
import CreatorContext from '@context/creatorContext';

import {
  List,
  Item,
  Title,
  SubTitle,
  Chords
} from './style';

const Breadcrumb = () => {
  const { state: creatorState } = useContext(CreatorContext);
  const history = useHistory();
  const list = ['/base','/acordes','/ticket','/impressao'];
  const index = list.indexOf(history.location.pathname);

  const [step1, changeStep1] = useState('off');
  const [base, baseChange] = useState('');
  const [step2, changeStep2] = useState(index < 1 ? 'disable' : 'off');
  const [step21, changeStep21] = useState(false);
  const [step22, changeStep22] = useState(false);
  const [step23, changeStep23] = useState(false);
  const step3 = index < 2 ? 'disable' : 'off';

  useEffect(() => {
    const fragranceIndex = creatorState.currentCreating;
    if (creatorState.fragrances.length > 0 &&
        creatorState.fragrances[fragranceIndex] &&
        creatorState.fragrances[fragranceIndex].variants &&
        creatorState.fragrances[fragranceIndex].variants.length > 0
      ) {
      changeStep1('active');
      baseChange(creatorState.fragrances[fragranceIndex].variants[0].name);
    }
    if (creatorState.fragrances.length > 0 &&
        creatorState.fragrances[fragranceIndex] &&
        creatorState.fragrances[fragranceIndex].variants &&
        creatorState.fragrances[fragranceIndex].variants.length > 0 &&
        creatorState.fragrances[fragranceIndex].variants[0].chords &&
        creatorState.fragrances[fragranceIndex].variants[0].chords.length > 0
      ) {
      changeStep2('active');
    }

    if(creatorState.fragrances.length > 0 &&
      creatorState.fragrances[fragranceIndex] &&
      creatorState.fragrances[fragranceIndex].variants &&
      creatorState.fragrances[fragranceIndex].variants.length > 0
    ) {
      if (creatorState.fragrances[fragranceIndex].variants.length >= 1) changeStep21(true)
      if (creatorState.fragrances[fragranceIndex].variants.length >= 2) changeStep22(true)
      if (creatorState.fragrances[fragranceIndex].variants.length >= 3) changeStep23(true)
    }
  }, [creatorState])

  return (
    <List>
      <Item className="first" active={step1 !== 'disable'} onClick={() => history.push('/base')}>
        <Title>
          <Check colorSchema={step1} />
          BASE
        </Title>
        <SubTitle>{base}</SubTitle>
      </Item>

      <Item className="center" active={step2 !== 'disable'} onClick={() => history.push('/acordes')}>
        <Title>
          <Check colorSchema={step2} />
          ACORDES
        </Title>

        {step2 !== 'disable' && (
          <>
            <Chords>{step21 ? '1' : '\u00A0'}</Chords>
            <Chords>{step22 ? '2' : '\u00A0'}</Chords>
            <Chords>{step23 ? '3' : '\u00A0'}</Chords>
          </>
        )}
      </Item>

      <Item className="last" active={step3 !== 'disable'}>
        <Title>
          <Check colorSchema={step3} />
          ETIQUETA
        </Title>
      </Item>
    </List>
  );
};

export default Breadcrumb;
