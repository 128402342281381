import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name }) => {
  return (
    <span style={{ color: '#fff' }} className={`icon-${name}`} />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
