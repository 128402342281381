import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR_FRAGRANCE, SET_CREATOR } from '@context/consts';

import FormService from '@api/services/form';
import Button from '@components/molecules/Button';

import Transition from '@components/molecules/Transition';
import BottomContainer from '@components/molecules/BottomContainer';
import {
  Label,
  ChordsContainer,
  StyledSlider,
  IncrementButton,
  DecrementButton,
  ChordName,
  Slider,
} from './style';

const SelectConcentrations = ({ setStep, activeVariant, setActiveVariant }) => {
  const history = useHistory();
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);
  const { fragrances, currentCreating } = creatorState;
  const variant = fragrances[currentCreating].variants[activeVariant-1];
  const { chords } = variant;

  if (!chords.length) {
    setStep(1);
  }

  const [selectedChords, setSelectedChords] = useState(chords);
  const [submited, setSubmited] = useState(false);
  const [sending, setSending] = useState(false);

  function defaultValue(position = null) {
    const num = selectedChords.length;

    if (num === 3) return 1;
    if (num === 2) return position === 0 ? 2 : 1;
    return 3;
  }

  function handleChange(event, value, chordCode) {
    const updatedChords = selectedChords.map(chord => {
      if (chord.code === chordCode) {
        return {
          ...chord,
          quantity: value
        }
      }
      return {
        ...chord
      }
    });

    setSelectedChords(updatedChords);
  }

  function handleOperation(chordCode, operation) {
    const updatedChords = selectedChords.map((chord, index) => {
      if (chord.code === chordCode) {
        if (operation === 'decrement') {
          return {
            ...chord,
            quantity: selectedChords[index].quantity -= 1
          }
        }
        return {
          ...chord,
          quantity: selectedChords[index].quantity += 1
        }
      }
      return {
        ...chord
      }
    });

    setSelectedChords(updatedChords);
  }

  function handleGoBack() {
    setStep('1');
  }

  function handleNext() {
    const totalQuantities = selectedChords.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator.toFixed(2)) + parseFloat(currentValue.quantity.toFixed(2));
    }, 0);

    if (totalQuantities !== 3) {
      const difference = 3 - totalQuantities;
      const textPartOrParties = Math.abs(difference) > 1 ? 'partes' : 'parte';
      if (difference > 0) {
        return toast.error(`Concentração inválida. Você precisa adicionar ${difference} ${textPartOrParties} de um acorde.`);
      }

      return toast.error(`Concentração inválida. Você precisa reduzir ${Math.abs(difference)} ${textPartOrParties} de um acorde.`);
    }

    variant.chords = selectedChords;
    variant.locked = true;
    setSubmited(true);
    return creatorDispatch({ type: SET_CREATOR_FRAGRANCE, payload: fragrances });
  }

  function handleNew() {
    if (fragrances[currentCreating].variants.length === 3) {
      creatorDispatch({ type: SET_CREATOR, payload: { currentCreating: 1 } })
      history.push('/base');
      return false;
    }

    if (!fragrances[currentCreating].variants[activeVariant]) {
      fragrances[currentCreating].variants.push({
        code: variant.code,
        name: variant.name,
        quantity: variant.quantity,
        reference: variant.reference,
        chords: [],
      });
    }

    creatorDispatch({ type: SET_CREATOR_FRAGRANCE, payload: fragrances });
    setActiveVariant(activeVariant + 1);
    return true;
  }

  function getDataSend() {
    const dataSend = {};
    dataSend.name = creatorState.name;
    dataSend.email = creatorState.email;
    dataSend.description = creatorState.description;
    dataSend.allergy = creatorState.allergy;
    dataSend.variants = [creatorState.fragrances[creatorState.currentCreating]?.variants[activeVariant-1]];
    dataSend.historic = [];

    creatorState.fragrances.forEach(fragrance => {
      fragrance.variants.forEach(va => {
        dataSend.historic.push(va);
      });
    });

    return dataSend;
  }

  async function handleSaveAndNext() {
    setSending(true);

    try {
      const dataSend = getDataSend();
      const res = await FormService.sendData(dataSend);

      const { lot, productCode, id } = res.data;

      const dataToState = {
        lot,
        productCode,
        id,
      }

      creatorDispatch({ type: SET_CREATOR, payload: dataToState });

      history.push('/ticket');
    } catch(err) {
      /* eslint-disable-next-line */
      console.log(err);
    } finally {
      setSending(false);
    }
  }

  function checkLocked() {
    if (fragrances[currentCreating].variants[activeVariant-1].locked) setSubmited(true);
  }

  function calcVolume(concentration) {
    const c = concentration * 5;
    return c <= 0 ? Number(0).toFixed(2) : (c > 15.00 ? 15.00 : c).toFixed(2);
  }

  useEffect(() => {
    if (!creatorState.fragrances[creatorState.currentCreating]?.variants[activeVariant-1].chords) setStep(1);
    else setSelectedChords(chords);

    checkLocked();
  }, [activeVariant]);

  return (
    <Transition type="fadein" trigger>
      <Label>Defina a concentração dos acordes de 1 a 3 partes, a soma total não deve ultrapassar 3:</Label>
      <ChordsContainer active={!submited}>
        {selectedChords.map((chord, index) => (
          <>
            <ChordName>{chord.name}</ChordName>
            <StyledSlider key={chord.code}>
              <DecrementButton active={!submited} onClick={() => handleOperation(chord.code, 'decrement')} disabled={calcVolume(chord.quantity) <= 0.00}>
                -
              </DecrementButton>

              <Slider
                defaultValue={defaultValue(index)}
                onChange={(event, value) => handleChange(event, value, chord.code)}
                value={chord.quantity.toFixed(1)}
                min={0}
                max={3}
                valueLabelDisplay="on"
              />

              <IncrementButton active={!submited} onClick={() => handleOperation(chord.code, 'increment')} disabled={calcVolume(chord.quantity) >= 15.00}>
                +
              </IncrementButton>
            </StyledSlider>
          </>
        ))}
      </ChordsContainer>

      {!submited ? (
        <>
          <BottomContainer>
            <BottomContainer.Item>
              <Button onClick={handleGoBack} type="ghost">
                ALTERAR ACORDES
              </Button>
            </BottomContainer.Item>
            <BottomContainer.Item>
              <Button onClick={handleNext} type="secondary">
                SALVAR OPÇÃO
              </Button>
            </BottomContainer.Item>
          </BottomContainer>
        </>
      ) : (
        <Transition type='fadein' trigger>
          <>
            <BottomContainer>
              <BottomContainer.Item>
                {fragrances.length < 2 && fragrances[currentCreating].variants.length === 3 ? (
                  <Button type="secondary" onClick={handleNew} reverseArrow>
                    CRIAR NOVA FRAGRÂNCIA
                  </Button>
                ) : fragrances[currentCreating].variants.length !== 3 && (
                  <Button type="secondary" onClick={handleNew} reverseArrow>
                    CRIAR NOVA OPÇÃO
                  </Button>
                )}
              </BottomContainer.Item>
              <BottomContainer.Item>
                <Button onClick={handleSaveAndNext} disabled={sending} loading={sending}>
                  FINALIZAR COM OPÇÃO
                  {` ${activeVariant}`}
                </Button>
              </BottomContainer.Item>
            </BottomContainer>
          </>
        </Transition>
      )}
    </Transition>
  )
}

SelectConcentrations.propTypes = {
  setStep: PropTypes.func,
  activeVariant: PropTypes.number,
  setActiveVariant: PropTypes.func,
};

SelectConcentrations.defaultProps = {
  setStep: undefined,
  activeVariant: 1,
  setActiveVariant: undefined,
};

export default SelectConcentrations;
