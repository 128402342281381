import { createGlobalStyle } from 'styled-components';

const domaineSans2 = '/static/fonts/text/DomaineSansDisplay-Regular.woff2';
const domaineSans = '/static/fonts/text/DomaineSansDisplay-Regular.woff';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Domaine';
    src: url(${domaineSans2}) format('woff2'), url(${domaineSans}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export default Fonts;
