import React from 'react';
import PropTypes from 'prop-types';

import { getErrorMessage } from '@modules/errors';

import {
  Viewport,
  TextAreaWrapper,
  StyledTextArea,
  Label,
  Error,
} from './style';

const TextArea = ({ label, placeholder, error, value, style, ...rest }) => {
  return (
    <Viewport>
      {label && (
        <Label>{label}</Label>
      )}

      <TextAreaWrapper>
        <StyledTextArea {...rest} error={error} placeholder={placeholder} style={style} defaultValue={value} />
      </TextAreaWrapper>

      <Error error={error}>{error && getErrorMessage(error)}</Error>
    </Viewport>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

TextArea.defaultProps = {
  label: undefined,
  placeholder: undefined,
  error: undefined,
  value: '',
  style: undefined,
};

export default TextArea;
