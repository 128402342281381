import styled from 'styled-components';
import { Error as ErrorColor, White, Secondary } from '@assets/styles/colors';
import { Paragraph } from '@assets/styles/typography';

export const LabelContainer = styled.label`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ removeBorder }) => removeBorder ? 'transparent' : '#343434'};
  border-radius: 16px;
  border: ${props => props.checked && !props.removeBorder ? `1px solid ${Secondary}` : '0'};

  & + label {
    margin-left: 36px;
  }
`;

export const InputContainer = styled.div`
`;

export const LabelText = styled.span`
  ${Paragraph};
  color: ${White};
`;

export const HiddenInput = styled.input`
  display: none;

  &:checked + div div {
    background: ${Secondary};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ big }) => big ? '26px' : '18px'};
  height: ${({ big }) => big ? '26px' : '18px'};
  border-radius: 50%;
  border: 1px solid ${({ error }) => error ? ErrorColor : Secondary};
  margin-right: 19px;
`;

export const Icon = styled.div`
  height: ${({ big }) => big ? '16px' : '12px'};
  width: ${({ big }) => big ? '16px' : '12px'};
  border-radius: 50%;
  background: transparent;
`;
