import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Viewport
} from './style';

const DEFAULT_CSS = {

};

const INITIAL = {
  fadein: {
    ...DEFAULT_CSS,
    opacity: 0,
  },
  fadeout: {
    ...DEFAULT_CSS,
    opacity: 1,
  }
}

const TRANSITIONS = {
  fadein: (val, setVal, delay) => {
    setTimeout(() => {
      setVal({
        ...val,
        opacity: 1,
      });
    }, delay)
  },
  fadeout: (val, setVal, delay) => {
    setTimeout(() => {
      setVal({
        ...val,
        opacity: 0,
      });
    }, delay)
  }
}

export default function Transition({ children, type, delay, duration, trigger }) {
  const initial = INITIAL[type];
  if (!initial) {
    throw new Error(`Invalid transition type: ${type}`);
  }
  const [css, setCss] = useState(initial);

  useEffect(() => {
    if (trigger) {
      TRANSITIONS[type](css, setCss, delay, duration);
    }
  }, [trigger]);

  return (
    <Viewport duration={duration} {...css}>
      {children}
    </Viewport>
  )
}

Transition.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['fadein', 'fadeout']).isRequired,
  trigger: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  delay: PropTypes.number,
};

Transition.defaultProps = {
  duration: 300,
  delay: 50,
}
