import styled from 'styled-components';
import { Secondary } from '@assets/styles/colors';
import { HeadingSmall } from '@assets/styles/typography';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
  margin-botom: 30px;
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 50px 0 50px;
  align-self: center;

  img {
    margin-bottom: 50px;
  }
`;
