import styled from 'styled-components';
import frascoImage from '@assets/images/frasco.png';
import closeImage from '@assets/images/close-dark.svg';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackButton = styled.div`
  background-size: cover;
  background-image: url(${closeImage});
  width: 38px;
  height: 38px;
  position: absolute;
  top: 71px;
  right: 47px;
  z-index: 1;
`;

export const PerfumeContainer = styled.div`
  position: relative;
  background-image: url('${frascoImage}');
  background-color: #bfc0c0;
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
`;

export const Bottle = styled.img`
  width: 100%;
  position: relative;
`;

export const Ticket = styled.div`
  width: 50%;
  height: 198px;
  position: absolute;
  bottom: ${p => p.offset}px;
  right: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Brand = styled.img`
  width: 128px;
`;

export const FraganceInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`;

export const FraganceName = styled.span`
  font-family: 'Domaine';
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 26px;
`;

export const By = styled.span`
  font-size: 20px;
`;

export const Signature = styled.span`
  font-family: 'Domaine';
  font-weight: bold;
  font-size: 23px;
`;
