import styled from 'styled-components';
import { LabelMedium } from '@assets/styles/typography';

export const Label = styled.span`
  ${LabelMedium};
`;

export const ChordsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  margin-top: 12px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
