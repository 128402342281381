import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Page from '@components/templates/Page';
import Input from '@components/molecules/Input';
import Button from '@components/molecules/Button';

import { signin } from '@utils/auth';

import logoImage from '@assets/images/logo.svg';

import {
  PageWrapper,
  Logo,
  TitleContainer,
  Title,
  // Label,
  Form,
  ButtonWrapper
} from './style';

const Signin = () => {
  const { handleSubmit, control, errors, formState } = useForm();
  const { isSubmitting } = formState;
  const history = useHistory();

  const handleSignIn = async (data) => {
    await signin(data);

    history.push('/');
  };

  return (
    <Page title="Signin" description="Signin Page" showNav={false}>
      <PageWrapper>
        <Logo src={logoImage} alt="Privée" />

        <TitleContainer>
          {/* <Label>Acesse o</Label> */}
          <Title>Personalize a Fragrância</Title>
        </TitleContainer>

        <Form onSubmit={handleSubmit(handleSignIn)}>
          <Input
            placeholder="Usuário"
            name="username"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            error={errors.username}
          />

          <Input
            type="password"
            placeholder="Senha"
            name="password"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            error={errors.password}
          />

          <ButtonWrapper>
            <Button
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              fullscreen
            >
              ACESSAR
            </Button>
          </ButtonWrapper>
        </Form>
      </PageWrapper>
    </Page>
  )
}

export default Signin;
