import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getErrorMessageByRequest } from '@modules/errors';
import FormService from '@api/services/form';

import Page from '@components/templates/Page';
import Header from '@components/molecules/Header';
import Button from '@components/molecules/Button';
import Input from '@components/molecules/Input';
import Breadcrumb from '@components/molecules/Breadcrumb';
import BottomContainer from '@components/molecules/BottomContainer';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR } from '@context/consts';

import {
  PageWrapper,
  Title,
  Label,
  InfosContainer,
  InfoItem,
  InfoLabel,
  Info,
  Form,
  TitleContainer,
} from './style';

const Ticket = () => {
  const { handleSubmit, control, errors } = useForm();
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);

  const notifyError = (msg) => toast.error(msg);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [fragranceName, setFragranceName] = useState(creatorState.fragranceName || '');
  const [signature, setSignature] = useState(creatorState.signature || '');
  const [perfumer, setPerfumer] = useState(creatorState.perfumer || '');

  function getFormattedLot() {
    if (!creatorState.lot) return history.push('/acordes');

    const { length } = creatorState.lot.toString();
    const zeros = 4-length;
    let finalString = '';

    for (let i=0; i<zeros; i+=1) {
      finalString += '0';
    }

    finalString += creatorState.lot;

    return finalString;
  }

  function verifyData() {
    if (!creatorState.fragranceName) return true;
    if (!creatorState.signature) return true;
    if (!creatorState.perfumer) return true;
    return false;
  }

  function handlePreview() {
    history.push('/preview');
  }

  async function sendData(data) {
    setLoading(true);

    creatorDispatch({ type: SET_CREATOR, payload: { ...data, positionPrint: 1 } });

    const { id } = creatorState;

    try {
      await FormService.insertData(data, id);
      history.push('/configurar-impressao');
    } catch(err) {
      const errorMessage = getErrorMessageByRequest(err);
      notifyError(errorMessage);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    creatorDispatch({ type: SET_CREATOR, payload: { fragranceName, signature, perfumer } })
  }, [fragranceName, signature, perfumer]);

  return (
    <Page title="Ticket" description="Ticket Page">
      <PageWrapper>
        <Header />

        <Breadcrumb />

        <TitleContainer>
          <Title>Etiqueta</Title>
          <Label>Preencha os campos abaixa para gerar uma etiqueta.</Label>
        </TitleContainer>

        <InfosContainer>
          <InfoItem>
            <InfoLabel>Código do produto</InfoLabel>
            <Info>{creatorState.productCode}</Info>
          </InfoItem>

          <InfoItem>
            <InfoLabel>Lote do produto</InfoLabel>
            <Info>{getFormattedLot()}</Info>
          </InfoItem>
        </InfosContainer>

        <Form onSubmit={handleSubmit(sendData)}>
          <Input
            placeholder="Nome da fragrância"
            name="fraganceName"
            defaultValue={fragranceName}
            control={control}
            rules={{ required: true, maxLength: 15 }}
            error={errors.fragranceName}
            onChange={(e) => setFragranceName(e.target.value)}
            maxLength="15"
          />

          <Input
            placeholder="Nome do cliente (assinatura do perfume)"
            name="signature"
            defaultValue={signature}
            control={control}
            rules={{ required: true, maxLength: 12 }}
            error={errors.signature}
            onChange={(e) => setSignature(e.target.value)}
            maxLength="12"
          />

          <Input
            placeholder="Perfumista responsável"
            name="perfumer"
            defaultValue={perfumer}
            control={control}
            rules={{ required: true, maxLength: 120 }}
            error={errors.fragrance_name}
            onChange={(e) => setPerfumer(e.target.value)}
            maxLength="120"
          />

          <BottomContainer>
            <BottomContainer.Item>
              <Button htmlType="button" type="secondary" onClick={handlePreview} reverseArrow disabled={verifyData()}>
                VISUALIZAR ETIQUETA
              </Button>
            </BottomContainer.Item>
            <BottomContainer.Item>
              <Button htmlType="submit" loading={loading} disabled={loading}>
                SALVAR E CONFIGURAR IMPRESSÃO
              </Button>
            </BottomContainer.Item>
          </BottomContainer>
        </Form>
      </PageWrapper>
    </Page>
  )
}

export default Ticket;
