import styled from 'styled-components';
import { LabelXLarge } from '@assets/styles/typography'
import { Secondary, Primary } from '@assets/styles/colors';

export const Viewport = styled.div`
  height: 100vh;
  width: 520px;
  background: ${Secondary};
  box-sizing: border-box;
  padding: 50px 25px;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;

  transition: all .3s;
  border-radius: 0 0 0 100px;

  transform: ${({ show }) => show ? 'translateX(0%)' : 'translateX(100%)'};
`;

export const CloseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display ${p => p.show ? 'block' : 'none'};
`;

export const Close = styled.div`
  padding: 25px;
  transform: rotate(180deg);
`;

export const Wrapper = styled.div`
  padding: 100px 25px 0;
`;

export const Title = styled.div`
  ${LabelXLarge};
  padding-left: 8px;
`;

export const Form = styled.form`
  margin-top: 16px;

  textarea {
    color: ${Primary};
  }
`;

export const ButtonContainer = styled.div`
  border-top: solid 1px ${Primary};
  padding-top: 120px;
`;
