import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Page from '@components/templates/Page';
import SelectChords from '@components/organisms/SelectChords';
import SelectConcentrations from '@components/organisms/SelectConcentrations';
import Header from '@components/molecules/Header';
import Breadcrumb from '@components/molecules/Breadcrumb';

import CreatorContext from '@context/creatorContext';

import Transition from '@components/molecules/Transition';
import {
  PageWrapper,
  // BackButton,
  TabsContainer,
  Tab,
  TabText,
  TitleContainer,
  Title,
  Label,
  BaseName
} from './style';

const Chords = () => {
  const history = useHistory();
  const { state: creatorState } = useContext(CreatorContext);
  const { fragrances, currentCreating } = creatorState;

  const selectedBase = fragrances[currentCreating]?.variants[0].name;

  const query = new URLSearchParams(history.location.search);
  const variant = parseInt(query.get('variant') || '1', 10);
  const step = `step-${query.get('step') || '1'}`;

  function setActiveVariant(val) {
    history.push(`/acordes?variant=${val}&step=1`);
  }

  function setStep(val) {
    history.push(`/acordes?variant=${variant}&step=${val}`);
  }

  function verifyData() {
    if (!selectedBase) history.push('/');
  }

  function verifyLock() {
    if (fragrances[currentCreating]?.variants[variant-1].locked) setStep('step-2');
  }

  useEffect(() => {
    verifyData();
    verifyLock();
  }, []);

  return (
    <Page title="Variantes" description="Escolha as variantes" showNav={false}>
      <PageWrapper>
        <Header />
        <Breadcrumb />

        <Transition type="fadein" trigger>
          <>
            <TitleContainer>
              <Title>Acordes</Title>
              <Label>
                {step === 'step-1' ? (
                  <>
                    Para combinar com
                    <BaseName>{` ${selectedBase}`}</BaseName>
                    , escolha até 3 acordes.
                  </>
                ) : (
                  <>
                    Crie até 3 opções com 3 variações de acordes cada.
                  </>
                )}
              </Label>
            </TitleContainer>

            <TabsContainer>
              <Tab
                active={variant === 1}
                onClick={() => setActiveVariant(1)}
              >
                <TabText>OPÇÃO 1</TabText>
              </Tab>

              <Tab
                active={variant === 2}
                disabled={fragrances[currentCreating]?.variants.length < 2}
                onClick={() => setActiveVariant(2)}
              >
                <TabText>OPÇÃO 2</TabText>
              </Tab>

              <Tab
                active={variant === 3}
                disabled={fragrances[currentCreating]?.variants.length < 3}
                onClick={() => setActiveVariant(3)}
              >
                <TabText>OPÇÃO 3</TabText>
              </Tab>
            </TabsContainer>

            {/* {fragrances[currentCreating]?.variants.length === 1 && !fragrances[currentCreating]?.variants[activeVariant-1].locked && (
          <BackButton htmlType="button" onClick={handleGoBack}>
            Escolher outra base
          </BackButton>
        )} */}

            {step === 'step-1' ? (
              <SelectChords setStep={setStep} activeVariant={variant} />
            ) : (
              <SelectConcentrations setStep={setStep} activeVariant={variant} setActiveVariant={setActiveVariant} />
            )}
          </>
        </Transition>
      </PageWrapper>
    </Page>
  )
}

export default Chords;
