export const SET_DATASEND = 'SET_DATASEND';
export const REMOVE_DATASEND = 'REMOVE_DATASEND';

export const SET_CREATOR = 'SET_CREATOR';
export const SET_CREATOR_FRAGRANCE = 'SET_CREATOR_FRAGRANCE';
export const SET_CREATOR_DESCRIPTION = 'SET_CREATOR_DESCRIPTION';
export const SET_CREATOR_CURRENT = 'SET_CREATOR_CURRENT';

export const SET_PRINT_SLOT = 'SET_PRINT_SLOT';

export const RESET_STATE = 'RESET_STATE';

export const SET_BREADCRUMB_STATE = 'SET_BREADCRUMB_STATE';
