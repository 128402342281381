import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledOption,
  OptionName,
  OptionDescription,
  Content,
  Image,
} from './style';

const ImageCard = ({ name, description, selected, available, imgSrc, ...rest }) => {
  return (
    <StyledOption selected={selected} disabled={!available} {...rest}>
      <Image src={imgSrc} alt="Base" />
      <Content>
        <OptionName selected={selected}>{name}</OptionName>
        <OptionDescription selected={selected}>{description}</OptionDescription>
      </Content>
    </StyledOption>
  );
};

ImageCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  available: PropTypes.bool,
};

ImageCard.defaultProps = {
  selected: false,
  available: true,
};

export default ImageCard;
