import styled from 'styled-components';
import { Secondary } from '@assets/styles/colors';
import { LabelLargeSemi, HeadingSmall, ParagraphHeight } from '@assets/styles/typography';

export const Viewport = styled.div`
  height: 100vh;
  width: 430px;
  background: ${Secondary};
  box-sizing: border-box;
  padding: 50px 25px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  transition: all .3s;
  border-radius: 0 0 100px 0;

  transform: ${({ show }) => show ? 'translateX(0%)' : 'translateX(-100%)'};
`;

export const CloseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display ${p => p.show ? 'block' : 'none'};
`;

export const Close = styled.div`
  padding: 25px;
`;

export const Actions = styled.div`
  padding-top: 100px;
`;

export const Item = styled.div`
  padding: 35px 25px;
  display: flex;
  align-items: center;
  opacity: ${({ active }) => active ? '1' : '0.5'};
  pointer-events: ${({ active }) => active ? 'inherit' : 'none'};
`;

export const Label = styled.div`
  ${LabelLargeSemi}
  padding: 0 15px;
`;

export const Exit = styled.div`
  padding: 25px;
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
`;


/* Confirmation */

export const ViewportConfirmation = styled.div`
  background: rgba(0,0,0,0.8);
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  display: ${({ show }) => show ? 'block' : 'none'};

  .confirmation-wrapper {
    height: auto;
    width: 700px;
    background: ${Secondary};
    box-sizing: border-box;
    padding: 60px 40px;

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 5;

    transform: translate(-50%, -50%);

    border-radius: 0 50px 50px 50px;

    h2 {
      ${HeadingSmall};
      margin-bottom: 15px;
      text-align: center;
    }

    p {
      ${ParagraphHeight};
      text-align: center;
      margin: 0 auto 75px;
      max-width: 470px;
    }

    button + button {
      margin-top: 30px;
    }
  }


`;
