import styled from 'styled-components';

import { Paragraph, HeadingLarge } from '@assets/styles/typography';
import { Primary, Secondary, Gray500 } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0;

  background: ${Primary};
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 200px 0 100px 0;
  max-width: 420px;
  align-self: center;
`;

export const Label = styled.span`
  ${Paragraph};
  color: ${Gray500};
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  ${HeadingLarge};
  color: ${Secondary};
  text-align: center;
`;

export const Form = styled.form`
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
`;

export default PageWrapper;
