import styled from 'styled-components';

import { Primary, Secondary } from '@assets/styles/colors';
import { LabelLarge } from '@assets/styles/typography';

const theme = {
  primary: {
    background: Secondary,
    border: '0',
    borderColor: 'none',
    '&:active': {
      background: Secondary
    },
    '& span': {
      color: Primary
    },
    boxShadow: '0px 14px 24px 5px rgba(0,0,0,0.36)',
  },
  secondary: {
    background: 'transparent',
    border: '1px solid',
    borderColor: Secondary,
    '&:active': {
      background: 'transparent'
    },
    '& span': {
      color: Secondary
    },
    boxShadow: '0px 14px 24px 5px rgba(0,0,0,0.36)',
  },
  dark: {
    background: Primary,
    border: '1px solid',
    borderColor: Secondary,
    '&:active': {
      background: Primary
    },
    '& span': {
      color: Secondary
    },
    boxShadow: '0px 14px 24px 5px rgba(0,0,0,0.36)',
  },
  ghost: {
    background: Primary,
    border: '0',
    borderColor: 'transparent',
    '& span': {
      color: Secondary
    }
  }
}

export const StyledButton = styled.button`
  ${props => theme[props.colorSchema]};
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: ${({ reverseArrow }) => reverseArrow ? '32px 32px 32px 0' : '0 32px 32px 32px' };
  outline: none;
  cursor: default;
  transition: .3s;
  opacity: ${props => props.disabled ? '.6' : '1'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

   ${({ border }) => border ? 'border: 1px solid #2B2B2B' : ''};

  &:active {
    transition: none;
  }

  .loader {
    opacity: ${props => props.loading ? '1' : '0'};
    visibility: ${props => props.loading ? 'visible' : 'hidden'};
    margin-right: ${props => props.loading ? '10px' : '-16px'};
    display: inline-block;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
  }

  span {
    ${LabelLarge};
    font-weight: 700;
    letter-spacing: 0.16em;
  }
`;

export default StyledButton;
