import React from 'react';
import PropTypes from 'prop-types';

import {
  LabelContainer,
  InputContainer,
  HiddenInput,
  IconContainer,
  Icon,
  LabelText,
} from './style';

const Radio = ({ name, value, label, onChange, error, checked, removeBorder, big }) => {
  return (
    <LabelContainer removeBorder={removeBorder} checked={checked}>
      <InputContainer>
        <HiddenInput type="radio" name={name} value={value} onChange={onChange} checked={checked} />
        <IconContainer big={big} error={error}>
          <Icon big={big} />
        </IconContainer>
      </InputContainer>
      <LabelText>{label}</LabelText>
    </LabelContainer>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.bool]),
  checked: PropTypes.bool,
  removeBorder: PropTypes.bool,
  big: PropTypes.bool,
};

Radio.defaultProps = {
  onChange: undefined,
  error: undefined,
  checked: false,
  removeBorder: false,
  big: false,
};

export default Radio;
