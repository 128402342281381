
export function saveStateReducer(name, reducer) {
  return (state, action) => {
    const newState = reducer(state, action);
    localStorage.setItem(`fsf_form_state_${name}`, JSON.stringify(newState));
    return newState;
  }
}

export function getInitialState(name, defaultState) {
  const storedState = localStorage.getItem(`fsf_form_state_${name}`);
  if (storedState) {
    try {
      return JSON.parse(storedState);
    } catch (e) {
      console.error(e);
    }
  }
  return defaultState;
}
