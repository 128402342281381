import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '@components/atoms/Loader';
import ImageCard from '@components/molecules/ImageCard';
import Header from '@components/molecules/Header';
import Button from '@components/molecules/Button';
import Page from '@components/templates/Page';
import Breadcrumb from '@components/molecules/Breadcrumb';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR_FRAGRANCE } from '@context/consts';

import loadForm from '@utils/form';

import Transition from '@components/molecules/Transition';
import BottomContainer from '@components/molecules/BottomContainer';
import {
  PageWrapper,
  BasesList,
  TitleContainer,
  Title,
  Label,
  BasesContainer,
} from './style';

const Base = () => {
  const history = useHistory();
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);

  const [bases, setBases] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadBases = useCallback(async () => {
    setLoading(true);

    const data = await loadForm();
    setBases(data.variants);

    setLoading(false);
  }, []);

  function verifyData() {
    if (!creatorState.name) history.push('/');
  }

  useEffect(() => {
    loadBases();
    verifyData();
  }, []);

  function getNewData(base) {
    const { currentCreating, fragrances } = creatorState;

    if (currentCreating === 0) {
      return [
        {
          variants: [
            {
              code: base.code,
              name: base.name,
              reference: base.reference,
              quantity: base.quantity,
            }
          ],
        }
      ];
    }

    fragrances[currentCreating] = {
      variants: [
        {
          code: base.code,
          name: base.name,
          reference: base.reference,
          quantity: base.quantity,
        }
      ]
    }

    return fragrances;
  }

  function handleSelectedOption(base) {
    const data = getNewData(base);

    creatorDispatch({ type: SET_CREATOR_FRAGRANCE, payload: data });
  }

  function checkIsSelected(code) {
    const { fragrances, currentCreating } = creatorState;
    const variantsCodes = fragrances[currentCreating]?.variants?.map(item => {
      return item.code;
    });
    return variantsCodes?.includes(code);
  }

  function handleNext() {
    history.push('/acordes')
  }

  return (
    <Page title="Base" description="Escolha uma base" showNav={false}>
      <PageWrapper>
        <Header />

        <Breadcrumb />

        <Transition type="fadein" trigger>
          <TitleContainer>
            <Title>Fragrância</Title>
            <Label>Para começar, escolha a base.</Label>
          </TitleContainer>

          <BasesList>
            { loading ? (
              <Loader size="large" />
            ) : (
              <Transition type="fadein" trigger>
                <BasesContainer>
                  { bases.map(base => (
                    <ImageCard
                      key={base.code}
                      name={base?.name}
                      imgSrc={`/static/img/bases/${base.code}.png`}
                      description={base.reference}
                      onClick={() => handleSelectedOption(base)}
                      selected={checkIsSelected(base.code)}
                    />
                  ))}
                </BasesContainer>
              </Transition>
            )}
          </BasesList>

          <BottomContainer>
            <Button htmlType="submit" onClick={handleNext} disabled={!creatorState.fragrances[creatorState.currentCreating]?.variants.length}>AVANÇAR</Button>
          </BottomContainer>
        </Transition>
      </PageWrapper>
    </Page>
  )
}

export default Base;
