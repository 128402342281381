/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { isAuthenticated } from '@utils/auth';

import PostPrint from '@views/PostPrint';
import {
  PrintPage,
  Signature,
  ProductCode,
  By,
  FraganceName,
  Lot,
  Title,
  Validity
} from './style';

const ViewTicket = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const logged = isAuthenticated();

  const position = query.get('position');
  const signature = query.get('signature');
  const fraganceName = query.get('fragranceName');
  const productCode = query.get('productCode');

  const str = query.get('lot');
  const pad = "0000";
  const lot = pad.substring(0, pad.length - str.length) + str;

  const d = new Date();
  d.setFullYear(d.getFullYear() + 2);
  const valid = `${d.getMonth() + 1}.${d.getFullYear().toString().slice(2, 4)}`;

  useEffect(() => {
    // window.print();
    const timer = setTimeout(() => {
      window.print();
      // window.alert('Quando a impressão for concluída, pressione OK.');
      // if (logged) history.push('/concluido');
      // else history.goBack();
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const TicketRender = index => {
    return (
      <section className="ticket" key={index}>
        {position === `${index}` && (
          <>
            <Title>
              <FraganceName>{fraganceName}</FraganceName>
              <By>by</By>
              <Signature>{signature}</Signature>
            </Title>
            <ProductCode>{productCode}</ProductCode>
            <Validity>
              <Lot>
                {valid}
                {'/'}
                {lot}
              </Lot>
            </Validity>
          </>
        )}
      </section>
    )
  }

  const items = [];
  for (let i = 0; i < 5; i+=1) {
    items.push(TicketRender(i));
  }

  return (
    <>
      <PrintPage />
      <PostPrint className="noprint" />
      <div className='flex-wrapper'>
        {items}
      </div>
    </>
  )
}

export default ViewTicket;
