import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PageHead from '@components/molecules/PageHead';
import { Viewport } from './style';

const Page = ({ children, title, description, className }) => {
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  useEffect(() => {
    const onResize = () => {
      if (document.activeElement.nodeName.toUpperCase() !== 'INPUT') { // skip if resize is due to keyboard showing on input focus
        setViewHeight(window.innerHeight);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('fullscreenchange', onResize);
  }, []);
  return (
    <Viewport viewHeight={viewHeight} className={className}>
      <PageHead
        title={title}
        description={description}
      />
      {children}
    </Viewport>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Page.defaultProps = {
  className: '',
};

export default Page;
