import React from 'react';
import PropTypes from 'prop-types';

const color = {
  disable: '#2B2B2B',
  off: '#6C6C6C',
  active: '#62B30D'
}

const Check = ({ colorSchema }) => (
  /* eslint-disable */
  <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="02.1_Fragrancia---seleção" transform="translate(-100.000000, -190.000000)" fill={color[colorSchema]}>
        <path d="M106.363961,199.899507 L104.949748,201.31372 L100,196.363972 L101.414214,194.949759 L106.363961,199.899507 Z M113.435025,190 L114.849238,191.414214 L106.363961,199.899507 L104.949744,198.485281 L113.435025,190 Z" id="basic-/-done" />
      </g>
    </g>
  </svg>
  /* eslint-enable */
);

Check.propTypes = {
  colorSchema: PropTypes.oneOf(['off', 'disable', 'active']),
};

Check.defaultProps = {
  colorSchema: 'off',
};

export default Check;
