import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '@components/molecules/Button';
import TextArea from '@components/molecules/TextArea';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR_DESCRIPTION } from '@context/consts';

import arrowImage from '@assets/images/arrow.svg';

import { CloseOverlay } from '@components/molecules/NavBar/style';
import {
  Viewport,
  Close,
  Wrapper,
  Title,
  Form,
  ButtonContainer,
} from './style';

const Annotation = ({ show, showChange }) => {
  const closeAnnotation = () => {
    showChange();
  };
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);
  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    if (data.description) {
      creatorDispatch({
        type: SET_CREATOR_DESCRIPTION,
        payload: data.description
      });
      closeAnnotation();
    }
  };

  return (
    <>
      <CloseOverlay show={show} onClick={closeAnnotation} />
      <Viewport show={show}>
        <Close onClick={closeAnnotation}>
          <img src={arrowImage} alt="Fechar Menu" />
        </Close>
        <Wrapper>
          <Title>Anotações do atendimento</Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextArea
              placeholder="Escreva suas anotações aqui"
              name="description"
              defaultValue={creatorState.description || ''}
              rules={{ required: true }}
              control={control}
              style={{ height: '500px' }}
            />
            <ButtonContainer>
              <Button type="dark" htmlType="submit">
                SALVAR
              </Button>
            </ButtonContainer>
          </Form>
        </Wrapper>
      </Viewport>
    </>
  );
};

Annotation.propTypes = {
  show: PropTypes.bool,
  showChange: PropTypes.func.isRequired
};

Annotation.defaultProps = {
  show: false
};

export default Annotation;
