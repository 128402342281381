import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Page from '@components/templates/Page';
import CreatorContext from '@context/creatorContext';
// import frascoImage from '@assets/images/frasco.png';
import logoImage from '@assets/images/logo-single.png';

import {
  PageWrapper,
  BackButton,
  PerfumeContainer,
  // Bottle,
  Ticket,
  Brand,
  FraganceInfos,
  FraganceName,
  Signature,
  By,
} from './style';

const ViewTicket = () => {
  const { state: creatorState } = useContext(CreatorContext);
  const history = useHistory();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  function onResize() {
    setInnerWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const offsetHeight = useMemo(() => {
    const ratio = 1600/2560;
    return ratio * innerWidth;
  }, [innerWidth]);

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Page title="View" description="Ticket Page" showNav={false}>
      <PageWrapper>
        <BackButton onClick={handleGoBack} />

        <PerfumeContainer>
          {/* <Bottle src={frascoImage} alt="frasco" /> */}
          <Ticket offset={offsetHeight}>
            <Brand src={logoImage} alt="Privée" />
            <FraganceInfos>
              <FraganceName>{creatorState.fragranceName}</FraganceName>
              <Signature>
                <By>by</By>
                {` ${creatorState.signature}`}
              </Signature>
            </FraganceInfos>
          </Ticket>
        </PerfumeContainer>
      </PageWrapper>
    </Page>
  )
}

export default ViewTicket;
