import styled from 'styled-components';

import { Gray500, Primary, Secondary } from '@assets/styles/colors';
import { LabelMediumStrong, ParagraphSmall } from '@assets/styles/typography';

export const StyledOption = styled.button`
  position: relative;
  width: 280px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${p => `linear-gradient(to left, #343434 50%, ${p.color} 50%)`};
  background-size: 200%;
  background-position: ${p => p.selected ? 'left' : 'right'};
  transition: all 200ms;
  border-radius: 8px;
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-bottom: 35px;
  cursor: default;
  text-align: left;
  box-shadow: 0px 14px 24px 0px rgba(0,0,0,0.36);
`;

export const OptionName = styled.span`
  ${LabelMediumStrong};
  font-weight: 600;
  display: block;
  color: ${props => props.selected ? `${Primary}` : `${Secondary}`};
  font-size: ${props => props.size === 'small' ? '18px' : '24px'};
  margin-left: 25px;
`;

export const OptionDescription = styled.span`
  ${ParagraphSmall};
  color: ${props => props.selected ? `${Primary}` : `${Gray500}`};
`;

export const Content = styled.div``;

export const BorderColor = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 9px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: ${({ color }) => color};
`;
