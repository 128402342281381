import API from '../index';

class AuthService extends API {
  async signin(data) {
    return this.post('/user/signin', data);
  }

  async me() {
    return this.get('/user/me');
  }
}

export default new AuthService();
