import styled from 'styled-components';
import { Paragraph, ParagraphSmall } from '@assets/styles/typography';
import { Secondary, Error as ErrorColor, White, Gray500 } from '@assets/styles/colors';

export const Viewport = styled.div`
  position: relative;
  margin-bottom: 80px;
  box-sizing: border-box;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  ${Paragraph};
  width: 100%;
  color: ${White};
  border: 0;
  border-bottom: 1px solid ${props => props.error ? ErrorColor : Secondary};
  background: transparent;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  transition: .3s;

  &::placeholder {
    color: ${Gray500};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  span {
    display: block;
  }
`;

export const Label = styled.span`
  ${ParagraphSmall}
  display: block;
  color: ${Gray500};
  position: absolute;
  bottom: 40px;
`;

export const Error = styled.span`
  ${ParagraphSmall}
  visibility: ${props => props.error ? 'visible' : 'hidden'};
  opacity: ${props => props.error ? '1' : '0'};
  position: absolute;
  bottom: ${props => props.error ? '-22px' : '-10px'};
  color: ${ErrorColor};
  display: block;
  transition: .3s cubic-bezier(.215,.61,.355,1);
`;
