import styled from 'styled-components';
import { HeadingSmall, LabelSmall, ParagraphHeight, ParagraphSmall } from '@assets/styles/typography';
import { Gray500, Primary, Secondary } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  height: 100vh;
  padding: 130px 100px 0;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  ${ParagraphSmall};
  background: none;
  border: 0;
  color: #4990E2;
  align-self: flex-start;
  margin-bottom: 14px;
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${Secondary};
  border-radius: 32px;
  margin-bottom: 32px;
  overflow: hidden;
`;

export const Tab = styled.button`
  width: 33.33%;
  border: none;
  background: ${({ active }) => active ? Secondary : 'transparent'};
  color: ${({ active }) => active ? Primary : Secondary};
  outline: none;
  padding: 22px;
  border-radius: 32px;
  cursor: default;

  span {
    opacity: ${({ disabled }) => disabled ? 0.3 : 1};
  }
`;

export const TabText = styled.span`
  ${LabelSmall};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px 0 32px 0;
  align-self: center;
`;

export const Label = styled.span`
  ${ParagraphHeight};
  color: ${Gray500};
  margin-top: 16px;
  text-align: center;
  display: block;
`;

export const Title = styled.h1`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
`;

export const BaseName = styled.span`
  color: ${Secondary};
`;
