import React from 'react';
import PropTypes from 'prop-types';

import { gotoFullscreen } from '@src/helpers/fullscreen';
import {
  Container,
  BottomContainerItem,
} from './style';

export default function BottomContainer({ children }) {
  return (
    <Container onClick={gotoFullscreen}>
      {children}
    </Container>
  );
}

BottomContainer.Item = BottomContainerItem;

BottomContainer.propTypes = {
  children: PropTypes.element.isRequired,
}
