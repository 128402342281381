import React from 'react';

import GlobalStyles from '@components/GlobalStyles';
import { AppProvider } from '@context/appContext.js';
import { CreatorProvider } from '@context/creatorContext.js';
import Router from './router';

function App() {
  return (
    <>
      <GlobalStyles />
      <CreatorProvider>
        <AppProvider>
          <Router />
        </AppProvider>
      </CreatorProvider>
    </>
  );

}

export default App;
