import API from '../index';

class FormService extends API {
  async loadForm() {
    return this.get('/form');
  }

  async sendData(data) {
    return this.post('/participant', data);
  }

  async insertData(data, id) {
    return this.put(`/participant/${id}`, data);
  }
  
  async search(filters) {
    const query = new URLSearchParams(filters).toString();
    return this.get(`/participant/search?${query}`);
  }
  
  async export(filters) {
    const query = new URLSearchParams(filters).toString();
    return this.get(`/participant/export?${query}`, { responseType: 'arraybuffer' });
  }
}

export default new FormService();
