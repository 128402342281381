import styled from 'styled-components';
import { HeadingSmall, LabelMediumStrong, LabelXSmall, Paragraph, ParagraphHeight } from '@assets/styles/typography';
import { Gray500, Primary, Secondary, White } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0;

  background: ${Primary};
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 85px 0 110px 0;
  align-self: center;
`;

export const Title = styled.h1`
  ${HeadingSmall};
  color: ${Secondary};
  text-align: center;
`;

export const SubTitle = styled.h2`
  ${ParagraphHeight};
  color: ${Gray500};
  text-align: center;
  padding-top: 14px;

  span {
    color: ${White};
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const Input = styled.input`
  ${Paragraph};
  width: 100%;
  color: ${White};
  border: 0;
  border-bottom: 1px solid ${Secondary};
  background: transparent;
  padding: 8px 40px 8px 0;
  box-sizing: border-box;
  outline: none;
  transition: .3s;

  &::placeholder {
    color: ${White};
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 0;
  top: 0px;
`;

export const Arrow = styled.div`
  position: absolute;
  top: 50px;
  left: 25px;
  padding: 25px;

  svg,
  g {
    fill: ${Secondary};
  }
`;

export const ResultWrapper = styled.div`
  z-index: 2;
  overflow-y: auto;
  padding: 0 100px 50px;
  position: absolute;
  top: ${({ top }) => top}px;
  bottom: 0;
  left: 0;
  right: 0;

  .loader {
    margin: 0 auto;
  }
`;

export const Card = styled.div`
  background: ${Primary};
  box-shadow: 0 -5px 20px rgba(0,0,0,0.1), 0 14px 24px rgba(0,0,0,0.36);
  border-radius: 8px;
  border-left: solid 9px ${Secondary};
  padding: 18px 25px;
  margin-bottom: 40px;
  color: ${White};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    ${LabelMediumStrong};
    letter-spacing: 0;
    display: block;
    margin-right: 16px;
  }

  span + span {
    font-weight: 400;
    color: ${Gray500};
    text-align: right;
    margin-right: 0;
  }
`;

export const DetailShow = styled.div`
  padding: 80px 0 0 0;
`;

export const FraganceSelected = styled.div`
  padding: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

export const ColTitle = styled.h3`
  ${LabelXSmall};
  color: ${Secondary};
  padding: 0 0 23px 0;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 100px;
  border: 1px solid #6C6C6C;
  border-radius: 16px;
`;

export const InfoLabel = styled.span`
  font-size: 16px;
  color: #6C6C6C;
  display: block;
  padding-bottom: 10px;
`;

export const InfoDetail = styled.strong`
  font-size: 32px;
  font-weight: 500;
  color: #6C6C6C;
  display: block;
`;

export const Perfumer = styled.div`
  padding: 30px 0 10px 0;
  border-bottom: solid 1px #fff;

  small {
    ${LabelXSmall};
    color:#4c4c4c;
    display: block;
    padding-bottom: 7px;
  }

  span {
    ${ParagraphHeight};
    color:#6b6b6b;
    display: block;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 55px;

  & + & {
    margin-top: 30px;
  }
`;
