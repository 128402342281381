import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { getInitialState, saveStateReducer } from '@context/utils';
import {
  SET_DATASEND,
  REMOVE_DATASEND,
  SET_BREADCRUMB_STATE,
  SET_PRINT_SLOT,
} from './consts';

const initialState = {
  print: {
    slot: 0,
  },
  breadcrumb: {},
  dataSend: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_PRINT_SLOT:
      return {
        ...state,
        print: {
          ...state.print,
          slot: payload,
        }
      }

    case SET_BREADCRUMB_STATE:
      return {
        ...state,
        breadcrumb: {
          ...state.breadcrumb,
          ...payload,
        },
      };

    case SET_DATASEND:
      return {
        ...state,
        dataSend: {
          ...state.dataSend,
          ...payload,
        }
      }

    case REMOVE_DATASEND:
      return {
        ...state,
        dataSend: {},
      }

    default:
      return state;
  }
};

const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(saveStateReducer('app', reducer), getInitialState('app', initialState));

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProvider };
export default AppContext;
