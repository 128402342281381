import styled from 'styled-components';
import { Gray500, Secondary } from '@assets/styles/colors';
import { HeadingSmall, ParagraphHeight } from '@assets/styles/typography';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h2`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
`;

export const Label = styled.span`
  ${ParagraphHeight};
  color: ${Gray500};
  margin-top: 16px;
  text-align: center;
  display: block;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 135px 0 56px 0;
  align-self: center;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 100px;
  border: 1px solid #6C6C6C;
  border-radius: 16px;

  & + div {
    margin-left: 80px;
  }
`;

export const InfoLabel = styled.span`
  font-size: 16px;
  color: #6C6C6C;
  margin-bottom: 3px;
`;

export const Info = styled.strong`
  font-size: 32px;
  font-weight: 500;
  color: #6C6C6C;
  line-height: 37px;
`;

export const Form = styled.form`
  margin-top: 80px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  & + div {
    margin-top: 30px;
  }
`;
