import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { isAuthenticated } from '@utils/auth';

const Route = ({ isPrivate, component: Component, ...rest }) => {
  const logged = isAuthenticated();

  return (
    <ReactDOMRoute
      {...rest}
      render={props =>
        !isPrivate || logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/entrar' : '/',
              state: { from: props.location }
            }}
          />
        )}
    />
  );
};

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  location:  PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
}

Route.defaultProps = {
  isPrivate: false,
  component: undefined,
  location: undefined
}

export default Route;
