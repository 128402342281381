import React, { useState } from 'react';

import Navbar from '@components/molecules/NavBar';
import Annotation from '@components/molecules/Annotation';

import menuImage from '@assets/images/menu.svg';
import logoImage from '@assets/images/logo.svg';
import commentImage from '@assets/images/comment.svg';


import {
  Viewport,
  Menu,
  Logo,
  Comment
} from './style';

const Header = () => {
  const [showNavbar, showNavbarChange] = useState(false);
  const [showAnnotation, showAnnotationChange] = useState(false);

  return (
    <>
      <Viewport>
        <Menu onClick={() => { showNavbarChange(!showNavbar); showAnnotationChange(false); }}>
          <img src={menuImage} alt="Menu" />
        </Menu>
        <Logo src={logoImage} alt="Privée" />
        <Comment onClick={() => { showAnnotationChange(!showAnnotation); showNavbarChange(false); }}>
          <img src={commentImage} alt="Comentários" />
        </Comment>
      </Viewport>

      <Navbar show={showNavbar} showChange={showNavbarChange} />
      <Annotation show={showAnnotation} showChange={showAnnotationChange} />
    </>
  );
};

export default Header;
