import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Page from '@components/templates/Page';
import Header from '@components/molecules/Header';
import Button from '@components/molecules/Button';
import Breadcrumb from '@components/molecules/Breadcrumb';
import Radio from '@components/atoms/Radio';
import logoHard from '@assets/images/logo-hard.png';

import AppContext from '@context/appContext';
import { SET_PRINT_SLOT } from '@context/consts';
import CreatorContext from '@context/creatorContext';
import {gotoFullscreen} from '@src/helpers/fullscreen';
import {
  PageWrapper,
  Title,
  Label,
  TitleContainer,
  TicketsContainer,
  RadioContainer,
  PaperWithTickets,
  LogoHardWrapper,
  LogoHard,
} from './style';

const PrePrint = () => {
  const { state: appState, dispatch: appDispatch } = useContext(AppContext);
  const { state: creatorState } = useContext(CreatorContext);
  const [printPosition, setPrintPosition] = useState(appState.print.slot);

  useEffect(() => {
    appDispatch({ type: SET_PRINT_SLOT, payload: (printPosition + 1)%5 });
  }, [printPosition])

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  return (
    <Page title="Configurar Impressão" description="PrePrint Page">
      <PageWrapper>
        <Header />

        <Breadcrumb />

        <TitleContainer>
          <Title>Etiqueta</Title>
          <Label>Selecione a posição da etiqueta e a orientação da página para impressão:</Label>
        </TitleContainer>

        <TicketsContainer>
          <RadioContainer>
            <Radio
              name="print-position"
              value={0}
              onChange={() => setPrintPosition(0)}
              checked={printPosition === 0}
              removeBorder
              big
            />
            <Radio
              name="print-position"
              value={2}
              onChange={() => setPrintPosition(1)}
              checked={printPosition === 1}
              removeBorder
              big
            />
            <Radio
              name="print-position"
              value={3}
              onChange={() => setPrintPosition(2)}
              checked={printPosition === 2}
              removeBorder
              big
            />
            <Radio
              name="print-position"
              value={4}
              onChange={() => setPrintPosition(3)}
              checked={printPosition === 3}
              removeBorder
              big
            />
            <Radio
              name="print-position"
              value={5}
              onChange={() => setPrintPosition(4)}
              checked={printPosition === 4}
              removeBorder
              big
            />
          </RadioContainer>
          <PaperWithTickets>
            <LogoHardWrapper selected={printPosition === 0} onClick={() => setPrintPosition(0)} aria-hidden="true">
              <LogoHard src={logoHard} alt="Privée oBoticario" />
            </LogoHardWrapper>

            <LogoHardWrapper selected={printPosition === 1} onClick={() => setPrintPosition(1)} aria-hidden="true">
              <LogoHard src={logoHard} alt="Privée oBoticario" />
            </LogoHardWrapper>

            <LogoHardWrapper selected={printPosition === 2} onClick={() => setPrintPosition(2)} aria-hidden="true">
              <LogoHard src={logoHard} alt="Privée oBoticario" />
            </LogoHardWrapper>

            <LogoHardWrapper selected={printPosition === 3} onClick={() => setPrintPosition(3)} aria-hidden="true">
              <LogoHard src={logoHard} alt="Privée oBoticario" />
            </LogoHardWrapper>

            <LogoHardWrapper selected={printPosition === 4} onClick={() => setPrintPosition(4)} aria-hidden="true">
              <LogoHard src={logoHard} alt="Privée oBoticario" />
            </LogoHardWrapper>
          </PaperWithTickets>
        </TicketsContainer>

        <Button
          htmlType="button"
          type="dark"
          reverseArrow
          onClick={() => {
            gotoFullscreen();
            history.push('/ticket');
          }}
        >
          VOLTAR PARA DADOS DA ETIQUETA
        </Button>
        <Button
          htmlType="button"
          onClick={() => {
            gotoFullscreen();
            history.push(
              `/impressao?position=${printPosition}&signature=${creatorState.signature}&fragranceName=${creatorState.fragranceName}&productCode=${creatorState.productCode}&lot=${creatorState.lot}`
            );
          }}
        >
          ENVIAR PARA IMPRESSÃO
        </Button>

      </PageWrapper>
    </Page>
  )
}

export default PrePrint;
