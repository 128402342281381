import cookie from 'js-cookie';
import { toast } from 'react-toastify';

import AuthService from '@api/services/auth';
import { getErrorMessageByRequest } from '@modules/errors';

const tokenName = `${process.env.REACT_APP_PROJECT_NAME}-token`;

export const isAuthenticated = () => {
  return !!cookie.get(tokenName);
}

export const signin = async ({ username, password }) => {
  try {
    const { data } = await AuthService.signin({
      username,
      password,
    });

    cookie.set(tokenName, data.accessToken, { expires: 1 });
  } catch(err) {
    const message = getErrorMessageByRequest(err);
    toast.error(message);
  }
};

export const logout = () => {
  cookie.remove(tokenName);
  window.location.reload();
};
