import { toast } from 'react-toastify';

import FormService from '@api/services/form';
import { getErrorMessageByRequest } from '@modules/errors';

const loadForm = async () => {
  try {
    const { data } = await FormService.loadForm();

    return data;
  } catch(err) {
    const message = getErrorMessageByRequest(err);
    return toast.error(message);
  }
};

export default loadForm;
