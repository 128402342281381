import React, { useContext, useEffect, useState } from 'react';

import Page from '@components/templates/Page';

import logoImage from '@assets/images/logo.svg';

import Transition from '@components/molecules/Transition';
import CreatorContext from '@context/creatorContext';
import { RESET_STATE } from '@context/consts';
import { gotoFullscreen } from '@src/helpers/fullscreen';
import AuthService from '@api/services/auth';

import {
  PageWrapper,
  Logo,
  Label,
  OptionsWrapper,
  OptionItem,
  OptionLabel,
  OptionDescription
} from './style';


const Home = () => {

  const { dispatch } = useContext(CreatorContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    dispatch({ type: RESET_STATE });
  }, []);

  async function getUser() {
    const { data } = await AuthService.me();
    setIsAdmin(data.role === 'admin');
  }

  useEffect(() => {
    getUser();
  }, [])

  return (
    <Page title="Home" description="Welcome to Nodo Csr!" showNav={false}>
      <Transition type="fadein" trigger>
        <PageWrapper>
          <Logo src={logoImage} alt="Privée" />

          <OptionsWrapper>
            <Label>Selecione uma das opções abaixo:</Label>

            {!isAdmin && (
              <>
                <OptionItem to="/registro" onClick={gotoFullscreen}>
                  <OptionLabel>Criar nova fragância</OptionLabel>
                  <OptionDescription>Criar fragrância a partir de um agendamento</OptionDescription>
                </OptionItem>
                <OptionItem to="/busca" onClick={gotoFullscreen}>
                  <OptionLabel>Buscar fragrância</OptionLabel>
                  <OptionDescription>Buscar por nome do cliente ou código do produto</OptionDescription>
                </OptionItem>
              </>
            )}

            {isAdmin && (
              <OptionItem to="/export" onClick={gotoFullscreen}>
                <OptionLabel>Relatório</OptionLabel>
                <OptionDescription>Buscar relatório entre duas datas</OptionDescription>
              </OptionItem>
            )}

          </OptionsWrapper>
        </PageWrapper>
      </Transition>
    </Page>
  )
}

export default Home;
