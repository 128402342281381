import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import patterns from '@modules/patterns';
import { getErrorMessage } from '@modules/errors';

import CreatorContext from '@context/creatorContext';
import { SET_CREATOR } from '@context/consts';

import Radio from '@components/atoms/Radio';
import Button from '@components/molecules/Button';
import Input from '@components/molecules/Input';
import TextArea from '@components/molecules/TextArea';
import Header from '@components/molecules/Header';
import Page from '@components/templates/Page';

import Transition from '@components/molecules/Transition';
import BottomContainer from '@components/molecules/BottomContainer';
import {
  PageWrapper,
  TitleContainer,
  Title,
  Label,
  Form,
  LabelForm,
  RadioGroup,
  Error,
} from './style';

const Step1 = () => {
  const { handleSubmit, control, errors } = useForm();
  const { state: creatorState, dispatch: creatorDispatch } = useContext(CreatorContext);
  const [submited, setSubmited] = useState(false);
  const history = useHistory();

  console.log('>>>creatorState:', creatorState);

  function hasAllergicError() {
    if (creatorState.isAllergic != null) return false;

    if (submited) {
      return {
        type: 'required',
        ref: {
          name: 'is_allergic',
        }
      };
    }

    return false;
  }

  async function onSubmit(data) {
    creatorDispatch({ type: SET_CREATOR, payload: data });
    setSubmited(true);

    if (creatorState.isAllergic != null) {
      history.push('/base');
    }
  };

  function onInvalid() {
    setSubmited(true);
  };

  useEffect(() => {
    // Trigger callback when there's errors
    if (Object.keys(errors).length !== 0) {
      onInvalid();
    }
  }, [JSON.stringify(errors)])

  return (
    <Page title="Informações" description="Cadastre as informações">
      <PageWrapper>
        <Header />

        <Transition type="fadein" trigger>
          <TitleContainer>
            <Title>Cadastro</Title>
            <Label>Para começar, informe seus dados:</Label>
          </TitleContainer>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder="Nome completo"
              name="name"
              defaultValue={creatorState.name || ''}
              control={control}
              rules={{ required: true }}
              error={errors.name}
            />

            <Input
              placeholder="E-mail"
              name="email"
              defaultValue={creatorState.email || ''}
              control={control}
              rules={{ required: true, pattern: patterns.email }}
              error={errors.email}
            />

            <LabelForm>Já teve reação alérgica a alguma fragrância?</LabelForm>

            <RadioGroup>
              <Radio
                name="is_allergic"
                value="no"
                label="Não"
                onChange={() => creatorDispatch({ type: SET_CREATOR, payload: { isAllergic: false } })}
                error={hasAllergicError()}
                checked={creatorState.isAllergic === false}
              />

              <Radio
                name="is_allergic"
                value="yes"
                label="Sim"
                onChange={() => creatorDispatch({ type: SET_CREATOR, payload: { isAllergic: true } })}
                error={hasAllergicError()}
                checked={creatorState.isAllergic === true}
              />
              <Error error={hasAllergicError()}>{hasAllergicError() && getErrorMessage(hasAllergicError())}</Error>
            </RadioGroup>

            {creatorState.isAllergic && (
              <TextArea
                placeholder="Descreva a reação alérgica"
                name="allergy"
                defaultValue={creatorState.allergy || ''}
                control={control}
                rules={{ required: true }}
                error={errors.allergy}
              />
            )}

            <BottomContainer>
              <Button htmlType="submit">CADASTRAR E AVANÇAR</Button>
            </BottomContainer>
          </Form>
        </Transition>
      </PageWrapper>
    </Page>
  );
}

export default Step1;
