import styled from 'styled-components';
import { Paragraph, LabelMedium } from '@assets/styles/typography';
import { Gray500, Error as ErrorColor, Secondary, White } from '@assets/styles/colors';

export const Viewport = styled.div`
  position: relative;
  margin-bottom: 32px;
`;

export const TextAreaWrapper = styled.div`
  position: relative;
`;

export const StyledTextArea = styled.textarea`
  ${Paragraph};
  line-height: 28px;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;

  color: ${White};
  border: 0;
  border-bottom: 1px solid ${props => props.error ? ErrorColor : Secondary};
  background: transparent;
  padding: 8px;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: ${Gray500};
  }
`;

export const Label = styled.span`
  ${LabelMedium}
  display: block;
  margin-bottom: 4px;
`;

export const Error = styled.span`
  ${LabelMedium}
  visibility: ${props => props.error ? 'visible' : 'hidden'};
  opacity: ${props => props.error ? '1' : '0'};
  position: absolute;
  bottom: ${props => props.error ? '-22px' : '-10px'};
  color: ${ErrorColor};
  display: block;
  transition: .3s cubic-bezier(.215,.61,.355,1);
`;
