import styled from 'styled-components';
import { Gray500, Secondary, White } from '@assets/styles/colors';
import { HeadingSmall, ParagraphHeight } from '@assets/styles/typography';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
`;

export const Label = styled.span`
  ${ParagraphHeight};
  color: ${Gray500};
  margin-top: 16px;
  text-align: center;
  display: block;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 135px 0 56px 0;
  align-self: center;
`;

export const TicketsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 68px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 63px 0;
  margin-right: 25px;

  label {
    margin: 0;
    height: 91px;
  }
`;

export const PaperWithTickets = styled.div`
  background: ${White};
  padding: 63px 25px;
  width: 458px;
  height: 655px;
`;

export const LogoHardWrapper = styled.div`
  padding: 15px 139px;
  border: 1px ${({ selected }) => selected ? 'solid' : 'dashed'} #000000;
  margin-bottom: 19px;
`;

export const LogoHard = styled.img`
  width: 130px;
`;