import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  box-sizing: border-box;
`;

export const BottomContainerItem = styled.div`
  &:not(:last-child) {
    width: 100%,
    margin-bottom: 30px;
  }
`
